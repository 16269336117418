var goToTop = {};

goToTop.jaVerify = false;
goToTop.btn = $('#ir-para-o-topo');

goToTop.verify = function(){
	if($(window).scrollTop() >= $('.topo').outerHeight()){
		goToTop.btn.addClass('active');
	}else{
		goToTop.btn.removeClass('active');
	}
}

goToTop.start = (function(){

	goToTop.btn.click(function(){
		$('body, html').animate({
			scrollTop : 0
		}, 1000);

		goToTop.btn.blur();
	})

	goToTop.verify();

	$(window).scroll(function(){
		if(goToTop.jaVerify) return;

		setTimeout(function(){
			goToTop.jaVerify = false;
		}, 100);

		goToTop.verify();
	});
})();