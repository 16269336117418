var fxTopo = {};

fxTopo.verify = function(){
	var topo = $('.topo');
	var faixa = $('.faixa-topo');

	if(window.midiaSize == 'xs' || window.midiaSize == 'sm'){
		if($(window).scrollTop() >= faixa.outerHeight()){
			topo.css('min-height', topo.outerHeight());
			topo.addClass('fx');
		}else{
			topo.removeAttr('style');
			topo.removeClass('fx');
		}
	}
}

fxTopo.jaVerify = false;

fxTopo.start = (function(){
	fxTopo.verify();

	$(window).scroll(function(){
		if(fxTopo.jaVerify) return;

		setTimeout(function(){
			fxTopo.jaVerify = false;
		}, 100);
		
		fxTopo.verify();
	});
})();