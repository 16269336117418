$('[data-toggle="categorias"]').click(function(){
	var app = document.querySelector('#app');

	$(app).toggleClass('show-categorias');

	var bgApp = document.createElement('div');

	$(bgApp).addClass('bg-app');

	$(bgApp).click(function(){
		$(app).removeClass('show-categorias');

		$('.bg-app').fadeOut(600, function(){
			$('.bg-app').remove();
		});
	});

	app.appendChild(bgApp);

	$('.bg-app').fadeIn(600)
});