var lazyBg = {};

lazyBg.jaLazyBg = false;

lazyBg.isOnRect = function(){
	$('[data-lazy-bg]').each(function(){
		if(this.getBoundingClientRect().top < (window.innerHeight + 200)){
			var bg = $(this).data('lazy-bg');

			$(this).css('background-image', `url(${bg})`);
			$(this).removeAttr('data-lazy-bg');
		}
	})
};

lazyBg.start = (function(){
	lazyBg.isOnRect();

	$(window).scroll(function(){
		if(lazyBg.jaLazyBg) return;

		setTimeout(function(){
			lazyBg.jaLazyBg = false;
		}, 100);

		lazyBg.isOnRect();
	});
})();