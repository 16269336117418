$('[data-toggle="buscas"]').click(function(){
	$('.buscas-mobile').addClass('active');

	var app = document.querySelector('#app');

	var bgBuscas = document.createElement('div');

	$(bgBuscas).addClass('bg-buscas');

	app.appendChild(bgBuscas);

	$(bgBuscas).click(function(){
		$('.buscas-mobile').removeClass('active');

		$('.bg-buscas').fadeOut(600, function(){
			$('.bg-buscas').remove();
		})
	})

	$(bgBuscas).fadeIn(600);
});