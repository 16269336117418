$('[data-toggle="menu"]').click(function(){
	var app = document.querySelector('#app');
	var bgMenu = document.createElement('div');

	$('.content-topo').addClass('active');

	$(bgMenu).addClass('bg-topo');

	$(bgMenu).click(function(){
		$('.content-topo').removeClass('active');

		$('.bg-topo').fadeOut(600, function(){
			$('.bg-topo').remove();
		})
	});

	app.appendChild(bgMenu);

	$(bgMenu).fadeIn(600);
});