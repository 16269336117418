Object.defineProperty(window, 'midiaSize', {
	get: function() {
		let w = window.innerWidth;
		let size = 'xs';

		if (w < 576) {
			size = 'xs';
		}

		else if (w >= 576) {
			size = 'sm';
		}

		else if (w >= 768) {
			size = 'md';
		}

		else if (w >= 992) {
			size = 'lg';
		}

		else if (w >= 1200) {
			size = 'xl';
		}

		return size;
	}
});
